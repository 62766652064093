<template>
  <div
    class="relative py-32 lg:h-full flex flex-col space-y-16 justify-center items-center z-50"
  >
    <DashboardPanel />

    <div class="px-6 sm:px-24">
      <h2
        class="mx-auto mt-6 max-w-md text-center text-lg leading-6 text-gray-100"
      >
        Subscribe to find out when the countdown for the next launch begins. 🚀
      </h2>
      <NewsletterForm class="mt-10 w-full" />

      <div class="w-full mx-auto text-center">
        <NuxtLink
          to="https://www.paypal.com/donate/?hosted_button_id=T96DNQA4G2UG4"
          :external="true"
        >
          <Button
            class="mt-10"
            variant="link"
          >
            Give it a boost by sponsoring it! 🚀
          </Button>
        </NuxtLink>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts"></script>
