<template>
  <form
    class="mx-auto flex max-w-xl gap-x-4"
    v-auto-animate
  >
    <label
      v-if="!loading && !subscribed"
      for="email-address"
      class="sr-only"
    >
      Email address
    </label>
    <div
      v-if="!loading && !subscribed"
      class="flex flex-col space-y-1 w-full"
    >
      <Input
        id="email-address"
        name="email"
        type="email"
        autocomplete="email"
        :placeholder="randomEmail"
        v-model="email"
        :class="{
          'will-change-transform border-red-500 animate-shake repeat-1 ease-in':
            formError,
        }"
      />
      <div class="text-xs ml-1 text-red-300">{{ formError }}</div>
    </div>
    <Button
      v-if="!subscribed"
      :disabled="loading || !email"
      variant="default"
      :class="{ 'mx-auto': loading }"
      @click="subscribe"
    >
      <Loader
        v-if="loading"
        class="mr-3"
      />
      <span v-if="loading">Launching... 🚀</span>
      <span v-else>Subscribe</span>
    </Button>

    <div
      v-if="subscribed"
      class="mx-auto text-center flex flex-col space-y-4"
    >
      <p class="text-green-400 text-balance text-xl font-bold">Thank you!</p>
      <p class="text-green-400">
        Our space station sent you a confirmation mail.
      </p>
    </div>
  </form>
</template>

<script setup lang="ts">
import { ref, computed } from "vue";

const randomEmails = ref([
  "ada.lovelace@victoriancomputing.co",
  "alan.turing@npl.co.uk",
  "vera.rubin@carnegiescience.edu",
  "ana.aslan@gerovital.eu",
]);

const randomEmail = computed(() => {
  return randomEmails.value[
    Math.floor(Math.random() * randomEmails.value.length)
  ];
});

const email = ref("");
const loading = ref(false);
const subscribed = ref(false);
const formError = ref<string | null>(null);

const validationFailedReset = () => {
  if (loading.value) {
    loading.value = false;
  }

  if (subscribed.value) {
    subscribed.value = false;
  }
};

const subscribe = async ($e: Event) => {
  $e.preventDefault();

  if (!email.value || loading.value || subscribed.value) {
    return;
  }

  loading.value = true;

  setTimeout(async () => {
    const { data, error, status } = await useFetch("/api/subscribe", {
      method: "POST",
      body: JSON.stringify({ email: email.value }),
    });

    if (error?.value) {
      console.log(error.value);
      console.log(status);
      console.log(data.value);

      if (error.value?.statusCode === 422) {
        validationFailedReset();
        formError.value = error.value?.data?.message;
        return;
      }

      formError.value = "An error occurred. Please try again later.";
      loading.value = false;
      return;
    }

    subscribed.value = true;
  }, 500);
};
</script>
