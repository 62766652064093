<template>
  <NuxtLayout>
    <!-- Header -->
    <InterstellarPage id="hero">
      <Header />
    </InterstellarPage>

    <!-- Features -->
    <InterstellarPage
      interstellarSpaceClass="opacity-50"
      :clouds="0"
    >
      <Features id="features" />
    </InterstellarPage>

    <!-- Dashboard -->
    <InterstellarPage>
      <Dashboard id="dashboard" />
    </InterstellarPage>

    <!-- Globe -->
    <!-- <InterstellarPage
      :clouds="0"
      interstellar-space-class="opacity-30"
    >
      <Globe id="globe" />
    </InterstellarPage> -->

    <Footer />
  </NuxtLayout>
</template>

<script setup lang="ts">
definePageMeta({
  title: "Soketi — Interstellar",
  description:
    "Ready for the next inter-planetary, real-time communication software? 🚀",
  image: "https://public-assets.thecodefather.co/soketi%2Fsoketi_darkbg.png",
});

useSeoMeta({
  themeColor: "#000000",
  colorScheme: "dark light",
  appleMobileWebAppCapable: "yes",
  appleMobileWebAppStatusBarStyle: "black",
  mobileWebAppCapable: "yes",
  title: "Soketi — Interstellar",
  description:
    "Ready for the next inter-planetary, real-time communication software? 🚀",
  twitterCard: "summary_large_image",
  twitterSite: "@soketirealtime",
  twitterCreator: "@soketirealtime",
  twitterImage:
    "https://public-assets.thecodefather.co/soketi%2Fsoketi-ipfs.jpeg",
  twitterTitle: "Soketi — Interstellar",
  twitterDescription:
    "Ready for the next inter-planetary, real-time communication software? 🚀",
  ogDescription:
    "Ready for the next inter-planetary, real-time communication software? 🚀",
  ogTitle: "Soketi — Interstellar",
  ogImage: "https://public-assets.thecodefather.co/soketi%2Fsoketi-ipfs.jpeg",
  ogImageUrl:
    "https://public-assets.thecodefather.co/soketi%2Fsoketi-ipfs.jpeg",
  ogType: "website",
  ogUrl: "https://interstellar.soketi.app",
  ogSiteName: "Soketi",
});
</script>
