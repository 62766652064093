<template>
  <div
    class="relative py-32 lg:h-full flex flex-col justify-center items-center"
  >
    <div class="mx-auto max-w-7xl px-6 lg:px-8">
      <dl
        class="mx-auto grid max-w-3xl grid-cols-1 gap-8 text-base leading-7 text-gray-300 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:gap-x-16"
      >
        <div
          v-for="feature in features"
          :key="feature.name"
          class="relative pl-9"
        >
          <Badge
            v-if="feature.badge"
            variant="outline"
            class="text-white text-xs font-light"
          >
            {{ feature.badge }}
          </Badge>
          <dt class="inline font-semibold text-white">
            <component
              :is="feature.icon"
              class="absolute left-1 top-1 h-5 w-5 text-white"
              aria-hidden="true"
            />
            {{ feature.name }}
          </dt>
          {{ " " }}
          <dd class="inline">{{ feature.description }}</dd>
        </div>
      </dl>

      <div class="pt-20">
        <div class="mx-auto max-w-7xl px-6 lg:px-8">
          <div
            class="mx-auto mt-10 flex flex-col sm:flex-row items-center justify-center space-y-28 sm:space-x-8 sm:space-y-0"
          >
            <NuxtImg
              class="w-full sm:w-1/3 object-contain will-change-transform repeat-infinite animate-levitating-15s"
              src="logos/pusher.svg"
              alt="Pusher"
              width="100%"
              height="48"
              loading="lazy"
              :placeholder="[150, 48]"
            />
            <NuxtImg
              class="w-full sm:w-1/3 object-contain will-change-transform repeat-infinite animate-levitating-25s"
              src="logos/ably.svg"
              alt="Ably"
              width="100%"
              height="48"
              loading="lazy"
              :placeholder="[150, 48]"
            />
            <NuxtImg
              class="w-full sm:w-1/3 object-contain will-change-transform repeat-infinite animate-levitating-20s"
              src="logos/mqtt.svg"
              alt="MQTT"
              width="100%"
              height="48"
              loading="lazy"
              :placeholder="[150, 48]"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import {
  LockClosedIcon,
  CheckBadgeIcon,
  BoltIcon,
  HomeModernIcon,
  CpuChipIcon,
  CubeTransparentIcon,
  ServerIcon,
  CodeBracketSquareIcon,
  ArrowPathIcon,
} from "@heroicons/vue/20/solid";

const features = [
  {
    name: "Decentr'd.",
    description:
      "No need to worry about the configs. It's using the network architectures that the current blockchain technologies are using.",
    icon: CubeTransparentIcon,
  },
  {
    name: "Powerful.",
    description:
      "Same technologies, but on a powerful backbone provided by IPFS. It's not rocket science... but it's close. Thus, we can reach the other planets.",
    icon: BoltIcon,
  },
  {
    name: "Resilient.",
    description:
      "Failures are inevitable, but the show must go on. It is able to catch up with the losses on its own, without any human intervention.",
    icon: CheckBadgeIcon,
  },
  {
    name: "Home Friendly.",
    description:
      "Anywhere there is a network, you can run it. Even if it's your own home — public networks are not a requirement.",
    icon: HomeModernIcon,
  },
  /* {
    name: "Hardened.",
    description:
      "The blockchain technologies are known to be secure, and so is Soketi. Wheter it's a public or private network.",
    icon: LockClosedIcon,
  }, */
  {
    name: "Unplug, plug, play.",
    description:
      "Pusher, Ably or MQTT? No code changes required. They are synced, so you can use them all at the same time.",
    icon: ArrowPathIcon,
  },
  {
    name: "AI & LLMs",
    description:
      "Leverage the market game within the real-time context — protect your customers with built-in AI models for sentiment analysis and much more.",
    icon: CpuChipIcon,
    badge: "Coming soon",
  },
  {
    name: "Function-as-a-Service.",
    description:
      "The network will have your code and it will run it for you. Zero knowledge required, and you use the languages you love.",
    icon: CodeBracketSquareIcon,
    badge: "Coming soon",
  },
  {
    name: "Storage-as-a-Service.",
    description:
      "Your files will be safe. The network stores it for you, either it is public or private. No disruptions, no data loss.",
    icon: ServerIcon,
    badge: "Coming soon",
  },
];
</script>
