<template>
  <div
    class="h-full w-full flex flex-col justify-center items-center z-50 opacity-100 space-y-20 px-6 sm:px-24"
  >
    <div class="mx-auto max-w-md text-center text-lg leading-8 text-gray-100">
      Launch deployments across the vast space using our interstellar platform.
    </div>
    <Card
      class="w-full max-w-3xl mx-auto bg-black border-white/20 repeat-infinite will-change-transform animate-levitating-20s"
    >
      <CardHeader
        class="flex justify-between border-b border-white/20 px-4 py-4 sm:px-6 sm:py-6 lg:px-8"
      >
        <h1 class="text-base font-semibold leading-7 text-white">
          Deployments
        </h1>
      </CardHeader>
      <CardContent>
        <!-- Deployment list -->
        <ul
          role="list"
          class="divide-y divide-white/5"
        >
          <li
            v-for="deployment in deployments"
            :key="deployment.id"
            class="relative flex items-center space-x-4 px-4 py-4 sm:px-6 lg:px-8"
          >
            <div class="min-w-0 flex-auto">
              <div class="flex items-center gap-x-3">
                <div
                  :class="[
                    statuses[deployment.status],
                    'flex-none rounded-full p-1',
                  ]"
                >
                  <div
                    class="h-2 w-2 rounded-full bg-current"
                    :class="{
                      'animate-pulse repeat-infinite will-change-transform':
                        deployment.status === 'pending',
                    }"
                  />
                </div>
                <h2 class="min-w-0 text-sm font-semibold leading-6 text-white">
                  <a
                    :href="deployment.href"
                    class="flex gap-x-2"
                  >
                    <span class="truncate">{{ deployment.teamName }}</span>
                    <span class="text-gray-400">/</span>
                    <span class="whitespace-nowrap">
                      {{ deployment.projectName }}
                      {{ deployment.regions.join(" ") }}
                    </span>
                    <span class="absolute inset-0" />
                  </a>
                </h2>
              </div>
              <div
                class="mt-3 flex items-center gap-x-2.5 text-xs leading-5 text-gray-400"
              >
                <p class="truncate">{{ deployment.type }}</p>
                <svg
                  viewBox="0 0 2 2"
                  class="h-0.5 w-0.5 flex-none fill-gray-300"
                >
                  <circle
                    cx="1"
                    cy="1"
                    r="1"
                  />
                </svg>
                <p class="whitespace-nowrap">{{ deployment.dns }}</p>
              </div>
            </div>
            <div
              :class="[
                states[deployment.state],
                'rounded-full flex-none py-2 px-3 text-xs font-light',
              ]"
            >
              {{ deployment.state }}
            </div>
          </li>
        </ul>
      </CardContent>
    </Card>
  </div>
</template>

<script setup>
import { cn } from "~/lib/utils";

const statuses = ref({
  online: "text-green-400 bg-green-400/10",
  pending: "text-blue-400 bg-blue-400/10",
});

const states = ref({
  Online: "text-green-400 bg-green-400/10 ring-green-400/20",
  Deploying: "text-indigo-400 bg-indigo-400/10 ring-indigo-400/30",
  Updating: "text-yellow-400 bg-yellow-400/10 ring-yellow-400/20",
});

const deployments = ref([
  {
    id: 1,
    href: "https://tny.app",
    teamName: "Chief Tools",
    projectName: "Tny",
    regions: ["🇪🇺", "🇺🇸"],
    status: "pending",
    dns: "tny-chief-tools.soketi.app",
    type: "Managed",
    state: "Deploying",
  },
  {
    id: 2,
    href: "https://cert.chief.app",
    teamName: "Chief Tools",
    projectName: "Cert Chief",
    regions: ["🇩🇪"],
    status: "online",
    dns: "certchief-euc1.soketi.app",
    type: "Serverless (AWS)",
    state: "Online",
  },
  {
    id: 3,
    href: "https://deploy.chief.app",
    teamName: "Chief Tools",
    projectName: "Deploy Chief",
    regions: ["🌏"],
    status: "online",
    dns: "deploychief.soketi.app",
    type: "Premise via Agents",
    state: "Online",
  },
]);
</script>
